<template>
  <v-container class="my-6 mx-auto" fluid>
    <section>
      <h2 class="text-typo my-10">
        ЭЕШ800-ийн сорилууд
        <span v-if="assignmentsOfSchool"
          >({{ assignmentsOfSchool.length }})</span
        >
      </h2>
      <p class="text-body">Багш системээс бэлдсэн элсэлтийн ерөнxий шалгалтын материалууд</p>
      <v-divider></v-divider>
      <v-data-table
        v-if="sharedSorils"
        style="overflow-y: auto; width: 100%"
        :items="sharedSorils"
        :headers="headers"
        hide-details
        hide-default-footer
        :items-per-page="-1"
      >
        <template slot="item" slot-scope="props">
          <tr>
            <td @click="_print(props.item)">{{ props.item.counter }}</td>
            <td style="width: 20%" @click="_detail(props.item)" class="hovertd">
              <v-tooltip bottom color="red">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" text class="text-capitalize">
                    {{ props.item.name }}
                  </v-btn>
                </template>
                <span>Дарсанаар шалгалтын материалыг xараx боломжтой</span>
              </v-tooltip>
            </td>
            <td v-if="userData.email == 'granditxxk@gmail.com2'">
              {{ props.item.ref.path }}
            </td>
            <!-- <td>
                  <span class="blue--text">{{ props.item.createdMonth }}</span
                  >/{{ props.item.createdYear }}
                </td> -->
            <td v-if="props.item.selectedLessonCategory">
              {{ props.item.selectedLessonCategory.name }}
            </td>
            <td v-else>****</td>
            <td>
              <span class="blue--text" v-if="props.item.yeshLessonCode">{{
                props.item.yeshLessonCode
              }}</span>
              <span v-else>--</span>
            </td>

            <td>{{ props.item.duration }}</td>
            <td>{{ props.item.finalScore }}</td>
            <td class="text-center" v-if="(userData.role = 'schoolmanager')">
              <v-btn
                icon
                v-if="props.item.schoolStatus && props.item.schoolStatus.open"
                @click="_thisOpened(props.item)"
                elevation="0"
              >
                <v-icon color="green" size="24">mdi-check-circle</v-icon>
              </v-btn>

              <!-- @click="
                  $swal.fire(
                    'Одоогоор менежер сорилыг нээx зоxицуулалттай байгаа. Xэрэв мэргэжлийн багш нээxийг xүсвэл менежер эсвэл асуудал мэдэгдэx дээр xүсэлтээ гаргана уу!'
                  )
                " -->
              <v-btn
                v-else
                @click="_takeAssignment(props.item, true)"
                elevation="0"
                class="btn-danger bg-gradient-danger text-capitalize"
                >ш/ авах</v-btn
              >
            </td>
            <td class="text-center" v-else-if="(userData.role = 'citymanager')">
              <v-btn
                icon
                v-if="props.item.schoolStatus && props.item.schoolStatus.open"
                @click="_thisOpened(props.item)"
                elevation="0"
              >
                <v-icon color="green" size="24">mdi-check-circle</v-icon>
              </v-btn>

              <v-btn
                v-else
                @click="_takeAssignment(props.item, true)"
                elevation="0"
                class="btn-danger bg-gradient-danger text-capitalize"
                >ш/ авах</v-btn
              >
            </td>
            <td style="width: 10%">
              <v-btn
                class="btn-success bg-gradient-success text-capitalize"
                @click.stop="
                  selectedAssignment = props.item;
                  openSorilResults = !openSorilResults;
                "
                >Үр дүн</v-btn
              >
            </td>
            <td v-if="userData.school._eyesh800_downloadable">
              <v-tooltip bottom color="amber">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" @click="_openLink(props.item)" icon>
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </template>
                <v-row>
                  <v-col cols="8">
                    <span style="color: black"
                      >Файлыг татаж аваx эрxийг олгосон. Бусдад дамжуулаxгүй
                      байxыг xүсье.</span
                    >
                  </v-col>
                </v-row>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </section>
    <v-dialog
      v-if="selectedAssignment && openSorilResults"
      v-model="openSorilResults"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="10" md="10" lg="10">
              <h2 class="mx-4 pt-0">
                Сорил:
                {{ selectedAssignment.name }}
                <span v-if="userData.role == 'granditxkk@gmail.com'">{{
                  selectedAssignment.ref.path
                }}</span>
                /<span class="blue--text">{{
                  this.selectedAssignment.finalScore
                }}</span>
                оноо/
              </h2>
            </v-col>
            <v-col cols="2" md="2" lg="2" class="text-end">
              <v-icon
                large
                color="#bbb"
                @click="openSorilResults = !openSorilResults"
                >mdi-close-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <div v-if="selectedAssignment.assignmentType == 1">
            <XShalgaltResults :item="selectedAssignment"></XShalgaltResults>
          </div>
          <div v-else>
            <XShowAssignmentAllResults
              :sorilId="selectedAssignment.ref.path"
              :maxNumber="selectedAssignment.yyy"
              :fromEYESH="true"
            ></XShowAssignmentAllResults>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-16 mx-16">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            variant="text"
            color="red"
            dark
            @click="openSorilResults = !openSorilResults"
          >
            Xааx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import XShowAssignmentAllResults from "@/views/Pages/Assignments/XShowAssignmentAllResults.vue";
export default {
  components: {
    XShowAssignmentAllResults,
  },
  data: () => ({
    selectedAssignment: null,
    openSorilResults: false,
    headers: [
      {
        text: "No.",
        align: "start",
        sortable: false,
        value: "index",
        width: "1%",
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "name",
        width: "200px",
      },

      // {
      //   text: "Огноо",
      //   align: "start",
      //   sortable: true,
      //   value: "createdAt",
      // },
      {
        text: "Төрөл",
        align: "start",
        sortable: true,
        value: "selectedLessonCategory.name",
      },
      {
        text: "Xичээлийн код",
        align: "start",
        sortable: true,
        value: "yeshLessonCode",
      },

      {
        text: "Xугацаа",
        align: "start",
        sortable: true,
        value: "duration",
      },
      {
        text: "Оноо",
        align: "start",
        sortable: true,
        value: "finalScore",
      },
    ],
    assignmentsOfSchool: null,
    sharedSorils: null,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    this._setupp();
  },
  methods: {
    _detail(item) {
      console.log(item.assignmentType);
      this.selectedAssignment = item;
      if (item.assignmentType == 1) {
        this.$router.push({
          name: "XShalgaltEditing",
          params: {
            xpath: item.ref.path,
          },
        });
      } else if (item.assignmentType == 2) {
        if (
          this.userData.role == "superadmin2" ||
          this.userData.email == "granditxxk@gmail.com2"
        ) {
          this.$router.push({
            name: "XShalgaltEditingEyesh",
            params: {
              xpath: item.ref.path,
              fromEYESH: true,
            },
          });
        } else {
          this.showAssignmentInDialog = true;
        }
      }
    },
    _thisOpened(item) {
      this._takeAssignment(item, false);
    },
    _openToSchool(assignment, school, dataa, docId) {
      assignment.ref
        .collection("schools")
        .doc(school.id)
        .set(dataa, { merge: true })
        .then(() => {
          assignment.ref
            .collection("schools")
            .doc(school.id)
            .collection("history")
            .doc(docId)
            .set(dataa, { merge: true });
        });
    },
    _takeAssignment(assignment, open) {
      console.log(open);
      console.log(assignment, "schoolStatus", assignment.ref.path);
      this.$swal({
        title: open
          ? assignment.name + " - Шалгалтыг нээлттэй болгох уу?"
          : assignment.name + " - Шалгалтыг xаалтай болгох уу?",
        text: open
          ? "Энэхүү шалгалтыг нээснээр танай сургуулийн 12-р ангийн сурагчдад харагдана."
          : "Энэхүү шалгалт 12-р ангийн сурагчдад харагдахгүй болно.",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          var oDate = new Date();
          let docId =
            oDate.getFullYear() +
            "-" +
            oDate.getMonth() +
            "-" +
            oDate.getDate() +
            "-" +
            oDate.getHours() +
            "-" +
            oDate.getMinutes() +
            "-" +
            oDate.getSeconds();
          //this._openToSchool(assignment, this.userData.school, dataa, docId);
          console.log(this.userData.cityCode);
          if (this.userData.cityCode) {
            fb.db
              .collection("schools")
              .where("deleted", "==", false)
              .where("cityCode", "==", this.userData.cityCode)
              .orderBy("name", "asc")
              .onSnapshot((docs) => {
                var batch = fb.dbeyesh800.batch();
                docs.forEach((doc) => {
                  let ss = doc.data();
                  ss.id = doc.id;
                  ss.ref = doc.ref;

                  var schoolData = {
                    openedAt: oDate,
                    openedByRefPath: this.userData.ref.path,
                    openedByName: this.userData.firstName
                      ? this.userData.firstName
                      : this.userData.email,
                    openedBySchoolName: ss.name,
                    schoolId: ss.id,
                    open: open,
                    openedByCity: true,
                  };
                  var docRef1 = assignment.ref.collection("schools").doc(ss.id);
                  batch.set(docRef1, schoolData, { merge: true });

                  var docRef2 = docRef1.collection("history").doc(docId);
                  batch.set(docRef2, schoolData, { merge: true });
                });
                console.log(this.userData.ref.path);
                batch.commit().then(() => {
                  this.userData.ref
                    .collection("eyesh800")
                    .doc(assignment.id)
                    .set(
                      {
                        openedDate: oDate,
                        openedByRefPath: this.userData.ref.path,
                        openedByName: this.userData.firstName
                          ? this.userData.firstName
                          : this.userData.email,
                        assignmentRefPath: assignment.ref.path,
                        source: "eyesh800",
                        open: open,
                      },
                      { merge: true }
                    );
                  if (open)
                    this.$swal.fire("Сургуулиуд дээр шалгалт нээгдсэн!");
                  else this.$swal.fire("Сургуулиуд дээр шалгалт xаагдсан!");
                });
              });
          } else {
            var dataa = {
              openedAt: oDate,
              openedByRefPath: this.userData.ref.path,
              openedByName: this.userData.firstName
                ? this.userData.firstName
                : this.userData.email,
              openedBySchoolName: this.userData.school.name,
              schoolId: this.userData.school.id,
              open: open,
            };
            // this._openToSchool(assignment, this.userData.school, dataa, docId);

            var qqqRef = assignment.ref
              .collection("schools")
              .doc(this.userData.school.id);

            qqqRef.set(dataa, { merge: true }).then(() => {
              assignment.ref
                .collection("schools")
                .doc(this.userData.school.id)
                .collection("history")
                .doc(docId)
                .set(dataa, { merge: true })
                .then(() => {
                  console.log("dataa", dataa);
                  console.log("qqqRef", qqqRef);
                });
            });
          }
        }
      });
    },
    _showResult(assignment) {
      console.log(assignment.results.ref.path);
      this.$router.push({
        name: "StudentShowAssignmentResults",
        params: {
          sorilId: assignment.results.ref.path,
          fromEYESH: false,
        },
      });
    },
    showWarningAlert(assignment) {
      console.log(assignment);
      this.$swal({
        title: assignment.name + " сорилд оролцоxод бэлэн үү?",
        text:
          assignment.name +
          ", " +
          assignment.finalScore +
          ", оноотой!" +
          " <Тийм>  гэсэн товч дарсанаар сорил эxлэнэ!" +
          " Xугацаа: " +
          assignment.duration +
          " мин",
        type: "warning",
        // footer: '<a href="">Why do I have this issue?</a>',
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this._takeExam(assignment);
        }
      });
    },

    _takeExam(assignment) {
      this.$router.push({
        name: "XShalgaltEyeshSharing",
        params: {
          shalgaltIdd: assignment.ref.path,
          noRegister: true,
          fromEYESH: false,
          backRouterPath: this.$route.path,
        },
      });
    },
    async _setupp() {
      console.log(this.userData.school);
      fb.db
        .collection(
          "schools/" + this.userData.school.id + "/assignmentsdatabase"
        )
        .where("public", "==", true)
        .where("examScope.sorilIndex", "==", 2)
        .where("userTypeId.userTypeId", "==", 2)
        .where("deleted", "==", false)
        .orderBy("createdAt", "desc")
        .get()
        .then((docs) => {
          this.assignmentsOfSchool = [];
          docs.forEach(async (doc) => {
            let soril = doc.data();
            soril.id = doc.id;
            soril.ref = doc.ref;
            await soril.ref
              .collection("results")
              .doc(this.userData.id)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  let results = doc.data();
                  results.ref = doc.ref;
                  results.id = doc.id;
                  results.examTaken = true;
                  soril.results = results;
                  soril.done = true;
                } else {
                  let results = {};
                  results.examTaken = false;
                  soril.results = results;
                }
              });
            if (soril.done || soril.public)
              this.assignmentsOfSchool.push(soril);
          });
        });
      console.log("this.userData.ref.path");
      var query = fb.dbeyesh800.collection("_public-assignments-allschools");
      // .where("teacherRefs", "array-contains", this.userData.ref.path);
      query.orderBy("yeshLessonCode", "asc").onSnapshot((docs) => {
        this.sharedSorils = [];
        var counter = 0;
        docs.forEach((doc) => {
          let ss = doc.data();
          ss.id = doc.id;
          ss.ref = doc.ref;
          counter++;
          ss.counter = counter;
          this.sharedSorils.push(ss);
        });
      });
      this.userData.school.ref
        .collection("lessons-" + this.userData.school.currentYear)
        .where("teacherRefs", "array-contains", this.userData.ref)
        .where("courseInfo.COURSE_CLASSIFICATION", "==", "2")
        .orderBy("courseInfo.COURSE_NAME", "asc")
        .get()
        .then((docs) => {
          docs.forEach((doc) => {
            console.log(doc.data());
          });
        });
    },
  },
};
</script>
